<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("address.address") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start custom-input whitebg-input m-2">
            <div class="mb-3">
              <label class="form-label bold-12 text-gray">
                {{ $t("address.country") }}
              </label>
              <v-select
                :options="countries"
                v-model="value.country_id"
                label="name"
                :placeholder="$t(`address.selectCountry`)"
                :reduce="(c) => c.id"
                v-on:option:selected="countryChange"
              />
            </div>
            <div class="row mb-3">
              <div class="col-3">
                <label class="form-label bold-12 text-gray">
                  {{ $t("address.postCode") }}
                </label>
                <input
                  type="text"
                  v-model="value.post_code"
                  class="form-control"
                />
              </div>
              <div class="col">
                <label class="form-label bold-12 text-gray">
                  {{ $t("address.city") }}
                </label>
                <input type="text" v-model="value.city" class="form-control" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label class="form-label bold-12 text-gray">
                  {{ $t("address.street") }}
                </label>
                <input
                  type="text"
                  v-model="value.street"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <label class="form-label bold-12 text-gray">
                  {{ $t("address.house") }}
                </label>
                <input type="text" v-model="value.house" class="form-control" />
              </div>
            </div>
          </div>

          <iframe
            style="height: 100%; width: 100%; border: 0"
            frameborder="0"
            :src="`https://www.google.com/maps/embed/v1/place?q=${value.country}, ${value.city}  ${value.street}  ${value.house}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";

const bootstrap = require("bootstrap");

export default {
  name: "Address",
  props: {
    modelValue: Object,
    show: Boolean,
  },
  emits: ["update:modelValue", "update:show", "change"],
  data: function () {
    return {
      needSave: false,
      saveTime: null,
      value: {
        country: this.modelValue?.country,
        country_id: this.modelValue?.country_id,
        city: this.modelValue?.city,
        street: this.modelValue?.street,
        house: this.modelValue?.house,
        post_code: this.modelValue?.post_code,
      },
      countries: [],
    };
  },
  mounted() {
    let myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      myModal.show();
    } else {
      myModal.hide();
    }
    this.showAddModal();
  },
  computed: {
    all() {
      return (
        this.value?.country +
        this.value?.country_id +
        this.value?.city +
        this.value?.street +
        this.value?.house +
        this.value?.post_code
      );
    },
  },
  watch: {
    all() {
      this.$emit("update:modelValue", {
        country: this.value?.country,
        country_id: this.value?.country_id,
        city: this.value?.city,
        street: this.value?.street,
        house: this.value?.house,
        post_code: this.value?.post_code,
      });

      this.needSave = true;
      if (this.saveTime === null) {
        this.saveTime = setTimeout(this.save, 2000);
      }
    },
    modelValue() {
      this.value = this.modelValue;
    },
    show() {
      let myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      if (this.show) {
        myModal.show();
      } else {
        myModal.hide();
      }
    },
  },
  methods: {
    countryChange() {
      if (this.countries.length > 0 && this.value.country_id > 0) {
        this.value.country = this.countries.find(
          (e) => e.id === this.value.country_id
        )?.name;
      }
    },
    save() {
      if (this.needSave) {
        this.$emit("change", this.modelValueData);
        this.needSave = false;
        if (this.saveTime !== null) {
          clearInterval(this.saveTime);
          this.saveTime = null;
        }
      }
    },
    showAddModal() {
      if (this.countries.length === 0) {
        http.fetch("/countries/all").then((data) => {
          this.countries = data;
        });
      }
    },
  },
  components: {},
};
</script>
